import React, { useEffect, useState } from 'react';

import {
  Divider,
  Form, FormInstance, Input, Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  createRulesForAntd, createValidatorTextField, phone, email,
} from '../../../../utils/validations';
import { useParticipantContext } from './context';
import { customizeRequiredMark } from '../../../Common/Form/Input/common';
import { AddressBook, useParticipantsGetById } from '../../../../hooks/api/addressBook';
import { useMessageError } from '../../../../hooks/common';
import useRouteBlocker from '../../../Common/RouteBlocker';
import { getOnlyValueRecursive, getUserRequestPath } from '../../../../utils';
import SelectPlace from '../../Orders/Form/Common/SelectPlace';
import { useAuth, UserRole } from '../../../../store/auth';
import SelectCompany from './SelectCompany';
import { isRoleEnough } from '../../../../enums/user';
import { useBlockerContext } from '../../../../context/blockerDisabler';
import AlternativePhoneNumberInput from '../../../Common/PhoneNumberInput/AlternativePhoneInput';

import styles from '../../../Common/Form/index.module.scss';

export const textFieldRequired: yup.StringSchema = createValidatorTextField([], true);
export const textField: yup.StringSchema = createValidatorTextField([], false);

export const validationCreateParticipant = (role?: UserRole) => yup.object().shape({
  // company: textFieldRequired,
  company: role === 'user' ? yup.object().shape({ id: yup.string() }) : yup.object().shape({ id: textFieldRequired }),
  displayName: textFieldRequired,
  companyName: textFieldRequired,
  // participantType: textFieldRequired,
  contactName: textFieldRequired,
  phone,
  email,
  country: textFieldRequired,
  address1: textFieldRequired,
  address2: textField,
  postalCode: textFieldRequired,
  city: textFieldRequired,
  state: textFieldRequired,
  tinNumber: textField,
  registerNumber: textField,
});

type ParticipantFormProps = AnyObject

function FullAddress({ form }: { form: FormInstance; }) {
  const { user } = useAuth();
  const validationRules = createRulesForAntd(validationCreateParticipant(user?.role));

  return (
    <>
      <SelectPlace
        addressName="address1"
        type={['country']}
        form={form}
        label="Country"
        name="country"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
        onSelect={() => {
          ['address1', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
            form.setFieldValue(key, undefined);
          });
        }}
      />
      <SelectPlace
        addressName="address1"
        type={['address']}
        form={form}
        label="Address"
        name="address1"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <Form.Item<AddressBook>
        label="Address 2"
        name="address2"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>
      <SelectPlace
        addressName="address1"
        type={['postal_code']}
        form={form}
        label="Postal code"
        name="postalCode"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <SelectPlace
        addressName="address1"
        type={['locality']}
        form={form}
        label="City / Town"
        name="city"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
      <SelectPlace
        addressName="address1"
        type={['administrative_area_level_1']}
        form={form}
        label="Province / Region / State"
        name="state"
        rules={[validationRules, { required: true, message: <div /> }]}
        extra={false}
      />
    </>
  );
}

export default function ParticipantForm(props: ParticipantFormProps): React.ReactNode | null {
  const { id } = useParams();
  const [form] = Form.useForm<AddressBook>();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useParticipantContext();
  const { user } = useAuth();
  const participantById = useParticipantsGetById(
    id ? `${getUserRequestPath(user?.role || 'user')}/participants/${id}` : '',
  );
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const routeBlocker = useRouteBlocker(
    () => (initialState === null ? getOnlyValueRecursive(form.getFieldsValue()) !== ''
      : (getOnlyValueRecursive(initialState) !== getOnlyValueRecursive(form.getFieldsValue()))),
    [initialState, form, triggerChange],
  );

  const { handleIsBlockerActive } = useBlockerContext();
  const { loading, data, error } = participantById;
  const validationSchema = validationCreateParticipant(user?.role);
  const validationRules = createRulesForAntd(validationSchema);

  useMessageError([participantById]);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data) {
      setInitialState(data);
      form?.setFieldsValue(data);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (initialState) {
      form.validateFields()
        .then(() => {
          setTimeout(() => setValid(false), 200);
        })
        .then(() => {
          handleIsBlockerActive(false);
        })
        .catch((error_) => console.error(error_));
    }
  }, [initialState]);

  return (
    <Form
      className={clsx(styles.form, styles.miniTopPadding)}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      requiredMark={customizeRequiredMark}
      onFieldsChange={async () => {
        setTriggerChange(Date.now());
        handleIsBlockerActive(true);
        const formValues = form.getFieldsValue();

        try {
          await validationSchema.validate(formValues, { abortEarly: false });
          setValid(true);
        } catch {
          setValid(false);
        }
      }}
      {...props}
      initialValues={{ ...initialState }}
            // onFieldsChange={() => {
      //   setTriggerChange(Date.now());

      //   setValid(!(Object.keys(form.getFieldsValue())
      //     .filter((key) => !(['address2', 'registerNumber', 'tinNumber'].includes(key)))
      //     .map((key) => form.getFieldValue(key))
      //     .some((value) => value === '' || value === undefined)
      //     || form.getFieldsError().some(({ errors }) => errors.length)));
      // }}
    >
      {isRoleEnough(user?.role, 'admin') && (
        <SelectCompany
          label="Customer"
          name={['company', 'id']}
          rules={[{ required: true, message: <div /> }]}
        />
      )}
      <Divider orientation="left">Company</Divider>
      <Form.Item<AddressBook>
        label="Display Name"
        name="displayName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<AddressBook>
        label="Company"
        name="companyName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      <Divider orientation="left">Contact</Divider>
      <Form.Item<AddressBook>
        label="Contact name"
        name="contactName"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input maxLength={35} />
      </Form.Item>

      <Form.Item<AddressBook>
        label="Mobile number"
        name="phone"
        rules={[validationRules, { required: true, message: <div /> }]}
        // getValueProps={formatPhoneNumber}
      >
        <AlternativePhoneNumberInput />
      </Form.Item>

      <Form.Item<AddressBook>
        label="Email"
        name="email"
        rules={[validationRules, { required: true, message: <div /> }]}
      >
        <Input />
      </Form.Item>
      <Divider orientation="left">Address</Divider>

      <FullAddress form={form} />
      <Divider orientation="left">Further information</Divider>
      <Form.Item<AddressBook>
        label="TIN number"
        name="tinNumber"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>

      <Form.Item<AddressBook>
        label="Register number"
        name="registerNumber"
        rules={[validationRules]}
      >
        <Input />
      </Form.Item>

      {loading ? (
        <div className={styles.spin}>
          <Spin />
        </div>
      ) : null}

      {routeBlocker.contextHolder}
    </Form>
  );
}
