import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col, Form, Modal, Row, Segmented,
} from 'antd';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { ReloadOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import {
  DeliveryNote,
  DeliveryNoteCreateParams,
  useDeliveryNoteGetByOrderId,
  useDeliveryNoteUpdate,
  useOrderDeliveryNoteCreate,
} from '../../../../../../../hooks/api/deliveryNotes';
import { useOrderContext } from '../../../../View/context';
import { ServiceUnitEnum } from '../../../../../../../hooks/api/services';
import { useOrderContextForm } from '../../../context';
import {
  ClientData, ClientDataGood, ClientDataPackages,
} from '../../../../Adapter';
import {
  getTotalGrossWeight,
  getTotalQuantity, getTotalVolume, getTotalWeight,
} from '../../../../../Invoices/View/DetailsContainer/useInvoiceCalculations';

import styles from './index.module.scss';

interface ModalFormDeliveryNoteProps {
  open: boolean;
  handleClose: () => void;
  afterSave?: (res: DeliveryNote) => void;
  itemId: string;
}

const formInitialState: DeliveryNoteCreateParams = {
  description: '',
  units: 'pieces',
};

export const extractUnNumbersFromForm = (goods: ClientDataGood[] | undefined) => [...new Set(
  (goods || [])
    .filter((item) => item?.dangerousGood_view !== undefined)
    .map((item) => item?.dangerousGood_view?.slice(0, 4) as string),
)];
export const extractUnNumbersFromRes = (goods: ClientDataGood[] | undefined) => (
  goods?.some((item) => item?.dangerousGood?.unNumber) ? (
    ` / ${[...new Set((goods || [])
      .map((item) => (item.dangerousGood?.unNumber ? `UN${item.dangerousGood.unNumber}` : ''))
      .filter(Boolean))].join(', ')}`
  ) : '');

function ModalFormDeliveryNote({
  open,
  handleClose,
  afterSave,
  itemId,
}: ModalFormDeliveryNoteProps) {
  const { id: orderId = '' } = useParams();
  const [form] = Form.useForm();
  const deliveryNoteById = useDeliveryNoteGetByOrderId(orderId);
  const deliveryNoteCreate = useOrderDeliveryNoteCreate(orderId);
  const deliveryNoteUpdate = useDeliveryNoteUpdate(orderId);
  const { order } = useOrderContext();
  const { forms: { packages: packagesForm, goods: goodsForm } } = useOrderContextForm();

  useEffect(() => {
    if (itemId) {
      deliveryNoteById.fetch();
    }
  }, [itemId]);

  const [initialValues, setInitialValues] = useState(formInitialState);

  useEffect(() => {
    if (itemId && deliveryNoteById.data && !deliveryNoteById.loading && !deliveryNoteById.error) {
      const { description, units } = deliveryNoteById.data;

      setInitialValues({
        description,
        units,
      });
    }
  }, [deliveryNoteById.data, itemId]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  /** Calculate totals for form */
  const goodsWatch: { goods: ClientDataGood[] } = Form.useWatch([], goodsForm) || { goods: [] };
  const packagesWatch: { packages: ClientDataPackages[], packageSummary?: string } = Form.useWatch([], packagesForm)
    || { packages: [] };

  const {
    totalQuantity,
    totalWeight,
    totalVolume,
    totalGross,
  } = useMemo(() => {
    const preparedClientData = { goods: goodsWatch, packages: packagesWatch } as ClientData;

    return ({
      totalQuantity: getTotalQuantity(preparedClientData),
      totalWeight: getTotalWeight(preparedClientData),
      totalVolume: getTotalVolume(preparedClientData),
      totalGross: getTotalGrossWeight(preparedClientData),
    });
  }, [goodsWatch, packagesWatch]);

  const handleSyncDescription = () => {
    const packages: { packages: ClientDataPackages[], packageSummary?: string } = packagesForm.getFieldsValue();
    const goods: { goods: ClientDataGood[] } = goodsForm.getFieldsValue();

    const goodsNames = !packages?.packages?.length
      ? goods?.goods?.map((item) => item.name).join(', ') : '';
    const unNumbers = extractUnNumbersFromForm(goods.goods);

    const newValue = `${packages.packageSummary || goodsNames || '-'} ${totalQuantity || 1}x, ${
      totalWeight || '-'}kg, ${totalVolume || '-'}m³ ${unNumbers.length > 0 ? `/ UN${unNumbers.join(', UN')}` : ''}`;

    form.setFieldValue('description', newValue);
  };

  /** Submit */
  const callbackFunc = (res: DeliveryNote | null) => {
    if (res?.id) {
      order.fetch();
      afterSave?.(res);
      handleClose();
    }
  };

  const handleSubmit = () => {
    if (!orderId) return;

    form.validateFields()
      .then((values) => {
        if (itemId) {
          deliveryNoteUpdate.fetch(values, itemId)
            .then(callbackFunc);
        } else {
          deliveryNoteCreate.fetch(values)
            .then(callbackFunc);
        }
      })
      .catch((_error) => console.log(_error));
  };

  const handleCloseClick = () => {
    setInitialValues(formInitialState);
    handleClose();
  };

  return (
    <Modal
      title={itemId ? `Update Delivery Note ${deliveryNoteById.data?.number || ''}` : 'Create Delivery Note'}
      open={open}
      onCancel={handleCloseClick}
      onOk={handleSubmit}
      okText="Save"
      okButtonProps={{
        loading: deliveryNoteCreate.loading || deliveryNoteUpdate.loading,
      }}
      width={900}
      destroyOnClose
      maskClosable={false}
      afterClose={() => form.setFieldsValue(formInitialState)}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={[16, 16]}>
          <Col span={21}>
            <Form.Item name="description" rules={[{ required: true }]}>
              <TextArea rows={3} placeholder="Description" maxLength={500} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button
              icon={<ReloadOutlined />}
              onClick={handleSyncDescription}
              disabled={false}
            >
              Sync
            </Button>
          </Col>

          <div className={styles.footer}>
            <Col>
              <Form.Item name="units">
                <Segmented
                  options={[
                    { value: 'pieces', label: ServiceUnitEnum.pieces },
                    { value: 'kilograms', label: ServiceUnitEnum.kilograms },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className={clsx(styles.flexAlignEnd, styles.totals)}>
                <div>
                  Total pieces:
                  <b>
                    {' '}
                    {totalQuantity || '-'}
                  </b>
                </div>
                <div>
                  Total gross:
                  <b>
                    {' '}
                    {totalGross || '-'}
                    kg
                  </b>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Form>
    </Modal>
  );
}

export default ModalFormDeliveryNote;
