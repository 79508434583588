import React, { useEffect, useMemo } from 'react';

import {
  App, Breadcrumb, Button, Dropdown, Form, MenuProps, Tag,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { ItemType as MenuItemType } from 'antd/es/menu/hooks/useItems';
import OrderForm from '../../Form';
import { useOrderContextForm } from '../../Form/context';
import {
  useOrderAcceptUpdate, useOrderCancelAssistance,
  useOrderCopy,
  useOrderDeclineUpdate,
  useOrderDelete,
  useOrderMarkAsDelivered,
  useOrderRestoreUpdate,
  useOrderSendTemplateEmail,
  useOrderSendTrackingLink,
} from '../../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../../hooks/common';
import Actions from '../../../../Common/Header/Actions';
import { useOrderContext } from '../context';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { OrderStatus } from '../../Adapter/enums';
import { getOrderStatusColor } from '../../index';
import { useAuth } from '../../../../../store/auth';
import { isRoleEnough } from '../../../../../enums/user';
import { useBlockerContext } from '../../../../../context/blockerDisabler';
import RequestAssistanceButton from '../../RequestAssistance';
import ClientPostOrderButton from '../../Form/Overview/ClientPostOrderButton';
import { asyncDelay } from '../../../../../utils';
import { useWatchSearchParam } from '../../../../../hooks/useSearchParams';

export function HeaderActions() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const { order, clientOrderData } = useOrderContext();
  const {
    loadingFile,
    orderUpdate,
    orderSave,
    orderProformaInvoicesGet,
    orderProformaInvoicesGenerate,
    ordersTransportDocumentsGet,
    ordersTransportDocumentsGenerate,
    generateProcess,
    handleNextTab,
    goodsForm,
    orderTypeForm,
    isEmailsChanged,
  } = useOrderContextForm();
  const { id } = useParams();
  const { user } = useAuth();
  const orderCopy = useOrderCopy(user?.role === 'user');
  const orderDelete = useOrderDelete(undefined, user?.role === 'user');
  const orderSendTrackingLink = useOrderSendTrackingLink(id);
  const orderAcceptUpdate = useOrderAcceptUpdate(id);
  const orderDeclineUpdate = useOrderDeclineUpdate(id);
  const orderMarkAsDelivered = useOrderMarkAsDelivered(id);
  const orderRestoreUpdate = useOrderRestoreUpdate(id);
  const orderSendTemplateEmail = useOrderSendTemplateEmail(id);
  const orderCancelAssistance = useOrderCancelAssistance(`${id}/cancel-request-support`);

  const isUserAdmin = isRoleEnough(user?.role, 'admin');
  const goodsLength = (Form.useWatch('goods', goodsForm) || []).length;
  const tabWatch = useWatchSearchParam('tab');

  const isAssistanceNeeded = Form.useWatch('isAssistanceNeeded', orderTypeForm);

  useMessageError([
    orderDeclineUpdate,
    orderAcceptUpdate,
    orderRestoreUpdate,
    orderCopy,
    orderDelete,
    orderSendTrackingLink,
    orderMarkAsDelivered,
    orderSendTemplateEmail,
    orderCancelAssistance,
  ]);
  useMessageSuccess([orderAcceptUpdate], 'Accept order successfully');
  useMessageSuccess([orderCopy], 'Duplicate created successfully');
  useMessageSuccess([orderDelete], 'Order deleted successfully');
  useMessageSuccess([orderRestoreUpdate], 'Restore order successfully');
  useMessageSuccess([orderSendTrackingLink], 'Tracking link sent successfully');
  useMessageSuccess([orderDeclineUpdate], 'Decline order successfully');
  useMessageSuccess([orderMarkAsDelivered], 'Mark as delivered successfully');
  useMessageSuccess([orderSendTemplateEmail], 'Email with template sent successfully');
  useMessageSuccess([orderCancelAssistance], 'Order canceled successfully');

  useEffect(() => {
    if ((!orderAcceptUpdate.error || !orderDeclineUpdate.error || !orderRestoreUpdate.error)
      || (orderMarkAsDelivered.data && !orderMarkAsDelivered.error && !orderMarkAsDelivered.loading)
      || (orderDeclineUpdate.data && !orderDeclineUpdate.error && !orderDeclineUpdate.loading)
      || (orderCancelAssistance.data && !orderCancelAssistance.error && !orderCancelAssistance.loading)
    ) {
      order.fetch();
      orderCancelAssistance.clearResponse();
    }
  }, [
    orderAcceptUpdate.response,
    orderDeclineUpdate.response,
    orderRestoreUpdate.response,
    orderMarkAsDelivered.response,
    orderCancelAssistance.response,
  ]);

  useEffect(() => {
    if (!orderCopy.error && !orderCopy.loading && orderCopy.data) {
      navigate(`/orders/${orderCopy.data.id}`); // /edit
    }
  }, [orderCopy.data]);

  const items = useMemo<MenuProps['items']>(() => {
    const template: { [key: string]: MenuItemType } = {
      DGAssistant: {
        key: 'Open in DGAssistant',
        label: 'Open in DGAssistant',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();

          window.open(
            `https://emea.dgassistant.com/Transport/Consignments/ConsignmentCreate.aspx?command=Update&con_id=${
              clientOrderData?.conId
            }&isforproducts=True`,
          );
        },
      },
      copy: {
        key: 'Make a copy',
        label: 'Make a copy',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderCopy.fetch({ id: id || '' });
        },
      },
      delete: {
        key: 'Delete',
        label: 'Delete',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete order?',
            content: (
              <span>
                Are you sure you want to delete order
                {' '}
                <b>{clientOrderData?.delivery?.orderNumber}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: {
              danger: true,
            },
            onOk: () => orderDelete.fetch(id).then((res) => {
              if (res?.success) {
                navigate('/orders');
              }
            }),
          });
        },
        danger: true,
      },
      resend: {
        key: 'Resend Tracking link',
        label: 'Resend Tracking link',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderSendTrackingLink.fetch();
        },
      },
      markAsDelivered: {
        key: 'Mark as delivered',
        label: 'Mark as delivered',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          orderMarkAsDelivered.fetch();
        },
      },
      // requestAssistance: {
      //   key: 'Request assistance',
      //   label: 'Request assistance',
      //   onClick: ({ domEvent }) => {
      //     domEvent.preventDefault();
      //     message.info('Request assistance click!');
      //   },
      // },
      cancelOrder: {
        key: 'Back to editing',
        label: 'Back to editing',
        onClick: ({ domEvent }) => {
          domEvent.preventDefault();
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Back to editing?',
            content: (
              <span>
                Are you sure you want to cancel and start editing order
                {' № '}
                <b>{clientOrderData?.delivery?.orderNumber}</b>
                ?
              </span>
            ),
            cancelText: 'Cancel',
            okText: 'Back to editing',
            okButtonProps: {
              danger: true,
            },
            onOk: () => orderCancelAssistance.fetch(),
          });
        },
      },
    };

    const cancelOrderIfManually = [
      ...(clientOrderData?.delivery?.shipmentCreation?.toLowerCase() === 'manually'
        ? [template.cancelOrder] : [])];

    switch (order?.data?.status) {
      case OrderStatus.DRAFT:
        return [
          ...(isUserAdmin ? [template.copy] : []),
          ...cancelOrderIfManually,
          template.delete,
        ];

      case OrderStatus.PENDING:
        return [
          ...(isUserAdmin ? [
            template.copy,
            ...cancelOrderIfManually,
          ] : []),
          ...(user?.role === 'user' ? [template.cancelOrder] : []),
          template.delete,
        ];

      case OrderStatus.TO_BE_PAID:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            template.copy,
            ...cancelOrderIfManually,
          ] : []),
          ...(user?.role === 'user' ? [template.cancelOrder] : []),
          template.delete,
        ];

      case OrderStatus.READY_FOR_DELIVERY:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            template.copy,
            template.delete,
          ] : []),
          ...cancelOrderIfManually,
          // template.delete,
        ];

      case OrderStatus.EXPIRED:
        return [
          ...(isUserAdmin ? [
            template.copy,
          ] : []),
          ...cancelOrderIfManually,
          template.delete,
        ];

      case OrderStatus.IN_PROGRESS:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            // template.generateTransport,
            // template.generateProforma,
            template.copy,
            template.delete,
          ] : []),
          ...cancelOrderIfManually,
          // ...(user?.role === 'user' ? [template.cancelOrder] : []),
          // template.delete,
        ];

      case OrderStatus.PROCESSING:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            template.copy,
            template.delete,
          ] : []),
          ...cancelOrderIfManually,
        ];

      case OrderStatus.DECLINED:
        return [
          ...(isUserAdmin ? [
            template.copy,
            template.delete,
          ] : []),
          ...cancelOrderIfManually,
        ];

      case OrderStatus.SHIPPED_OUT:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            template.resend,
            template.markAsDelivered,
            template.copy,
            template.delete,
          ] : []),
          ...cancelOrderIfManually,
        ];
      case OrderStatus.ON_THE_WAY:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            template.resend,
            template.markAsDelivered,
            template.copy,
            template.delete,
          ] : []),
          ...cancelOrderIfManually,
        ];
      case OrderStatus.REJECTED:
        return [
          ...(isUserAdmin ? [
            template.DGAssistant,
            template.copy,
          ] : []),
          ...cancelOrderIfManually,
          template.delete,
        ];

      case OrderStatus.DELIVERED: return [
        ...(isUserAdmin ? [
          template.DGAssistant,
          template.copy,
          template.delete,
        ] : []),
      ];

      default: return [];
    }
  }, [order]);

  const handleRequestAssistance = async () => {
    orderTypeForm.setFieldValue('isAssistanceNeeded', true);
    await asyncDelay(20);

    return await orderSave()
      .then((res) => {
        if (res) {
          return { orderId: res.id };
        }

        return undefined; // throw new Error('Order save failed');
      });
  };

  return (
    <Actions>
      {clientOrderData?.status ? (
        <Tag color={getOrderStatusColor(clientOrderData?.status)} style={{ width: 'auto' }}>
          {clientOrderData?.status}
        </Tag>
      ) : null}

      {isUserAdmin && clientOrderData?.status === OrderStatus.DECLINED ? (
        <Button
          danger
          loading={orderRestoreUpdate.loading}
          onClick={(e) => {
            e.preventDefault();
            orderRestoreUpdate.fetch();
          }}
        >
          Restore
        </Button>
      ) : null}
      {isUserAdmin && clientOrderData && [
        // OrderStatus.DRAFT,
        OrderStatus.PENDING,
        OrderStatus.IN_PROGRESS,
        OrderStatus.TO_BE_PAID,
      ]
        .includes(clientOrderData?.status) ? (
          <Button
            danger
            loading={orderDeclineUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              orderDeclineUpdate.fetch();
            }}
          >
            Decline
          </Button>
        ) : null}

      {isUserAdmin && clientOrderData && [
        // OrderStatus.DRAFT,
        OrderStatus.PENDING,
      ]
        .includes(clientOrderData?.status) ? (
          <Button
            type="primary"
            loading={orderAcceptUpdate.loading}
            onClick={(e) => {
              e.preventDefault();
              orderAcceptUpdate.fetch();
            }}
          >
            Accept
          </Button>
        ) : null}

      {isUserAdmin ? (
        <>
          <Button
            loading={orderProformaInvoicesGet.loading || !!generateProcess}
            onClick={(e) => {
              e.preventDefault();
              orderProformaInvoicesGenerate();
            }}
          >
            Generate Proforma Invoice
          </Button>
          <Button
            loading={ordersTransportDocumentsGet.loading || !!generateProcess}
            onClick={(e) => {
              e.preventDefault();
              ordersTransportDocumentsGenerate();
            }}
          >
            Generate Transport Documents
          </Button>
        </>
      ) : null}

      {user?.role === 'user' && goodsLength && tabWatch?.[0] !== 'Overview' && clientOrderData && [
        OrderStatus.DRAFT, OrderStatus.REJECTED,
        // OrderStatus.IN_PROGRESS,
        // if user PAID invoice, and then he got error from dhl - we need to add ability to asist
      ].includes(clientOrderData?.status) ? (
        <RequestAssistanceButton
          loading={orderUpdate.loading || loadingFile}
          beforeApiRequest={handleRequestAssistance}
        />
        ) : null}
      {/** For user we display 'copy' button always */}
      {user?.role === 'user' ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            orderCopy.fetch({ id: id || '' });
          }}
          loading={orderCopy.loading}
        >
          Make a copy
        </Button>
      ) : null}

      <Button
        type={isRoleEnough(user?.role, 'admin') ? 'primary' : 'default'}
        // disabled={!isValid}
        loading={orderUpdate.loading || loadingFile}
        onClick={() => {
          if (isEmailsChanged) {
            message.error('You save unsaved changes in emails tab. Save or leave the tab first.');
          } else {
            orderSave()
              .catch((_error) => console.error(_error));
          }
        }}
      >
        Save
      </Button>

      <ClientPostOrderButton />

      {user?.role === 'user' && tabWatch?.[0] !== 'Overview' ? (
        <Button
          type="primary"
          loading={orderUpdate.loading || loadingFile}
          onClick={handleNextTab}
        >
          Next Step
        </Button>
      ) : null}

      <Dropdown
        menu={{
          items: [user?.role !== 'user' ? {
            key: 'Send the order template',
            label: 'Send the order template',
            onClick: ({ domEvent }) => {
              domEvent.preventDefault();
              orderSendTemplateEmail.fetch();
            },
          } : null, ...(items || [])],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          loading={
            orderCopy.loading
            || orderDelete.loading
            || orderAcceptUpdate.loading
            || orderDeclineUpdate.loading
            || orderMarkAsDelivered.loading
            || orderSendTrackingLink.loading
            || orderSendTemplateEmail.loading
          }
          icon={<EllipsisOutlined />}
          style={{
            transform: 'translate(0px, 1.5px)',
            display: !isUserAdmin && !items?.length ? 'none' : 'unset',
          }}
        />
      </Dropdown>

      {contextHolder}
    </Actions>
  );
}

function OrderEdit(): React.ReactNode | null {
  const { id = '' } = useParams<{ id: string }>();
  const { clientOrderData } = useOrderContext();
  const { handleIsBlockerActive } = useBlockerContext();

  const { message } = App.useApp();
  const { user } = useAuth();
  const navigate = useNavigate();

  /** Prevent user from editing order if status is not New/Draft */
  useEffect(() => {
    if (user?.role === 'user' && clientOrderData?.status
      && ![
        OrderStatus.DRAFT,
        OrderStatus.TO_BE_PAID,
        // Prepayment users has to click button Place order in RFD status.
        OrderStatus.READY_FOR_DELIVERY,
        OrderStatus.IN_PROGRESS,
      ].includes(clientOrderData?.status)) {
      handleIsBlockerActive(false)
        .then(() => {
          message.warning('You are not allowed to edit this order!');
          navigate(`/orders/${id}`, { replace: true });
        }).then(() => handleIsBlockerActive(null));
    }
  }, [user?.role, clientOrderData?.status]);

  return (
    <>
      <Breadcrumb
        className="transparent"
        items={[
          {
            title: <NavLink to="/orders">Orders</NavLink>,
          },
          {
            title: (
              <NavLink to={`/orders/${id}`}>
                {`Order${clientOrderData ? ` №${clientOrderData?.delivery?.orderNumber}` : ''}`}
              </NavLink>
            ),
          },
          {
            title: 'Edit order',
          },
        ]}
      />

      <OrderForm />
    </>
  );
}

export default OrderEdit;
