import React, {
  CSSProperties, useCallback, useEffect,
} from 'react';
import {
  App,
  Button, Form, Upload, UploadFile, UploadProps,
} from 'antd';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import { FormItemProps } from 'antd/es/form/FormItem';
import { UploadOutlined } from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import clsx from 'clsx';
import { useUploadFile } from '../../../../../../hooks/file';
import { FormName, useOrderContextForm } from '../../context';
import { useFileDownload } from '../../../../../../hooks/downloadNotification';

import styles from '../../index.module.scss';

export interface File extends UploadFile {
  loading: boolean;
}

interface OrderUploadProps extends FormItemProps, FormName {
  prefix?: string | string[];
  rules?: Rule[];
  label?: string | React.ReactNode;
  name: NamePath<string>;
  style?: CSSProperties;
  children?: React.ReactNode;
  uploadProps?: UploadProps;
  rightText?: string | false;
  rest?: AnyObject;
  multiple?: boolean;
  maxLength?: number;
}

function OrderUpload({
  multiple = false,
  uploadProps = {},
  rest = {},
  formName, name, prefix, style, children, rightText, className, maxLength, ...props
}: OrderUploadProps): React.ReactNode | null {
  const { message } = App.useApp();
  const { forms: { [formName]: form }, setLoadingFile } = useOrderContextForm();
  const { handleDownload } = useFileDownload();

  const uploader = useUploadFile();
  const fullName = [
    // eslint-disable-next-line no-nested-ternary
    ...(prefix ? (Array.isArray(prefix) ? [...prefix] : [prefix]) : []),
    ...(Array.isArray(name) ? [...name] : [name]),
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const files: { file: any, fileList: any[] } = Form.useWatch(fullName, form);

  const findListAndCurrentUploading = useCallback(() => {
    const list = ((form.getFieldValue(fullName)?.fileList || []) as File[]).map((data) => {
      if (!data.status) {
        Object.assign(data, {
          status: 'uploading',
          loading: false,
        });
      }

      return data;
    });

    return {
      list,
      uploading: list.find(({ status }) => status === 'uploading'),
    };
  }, [form]);

  useEffect(() => {
    if (files) {
      // If file with which we interact is in fileList - it means he is being added, so in this case we do validation.
      if ((files?.file && files.fileList.some((item) => item.id === files.file.id)) // item.uid === files.file.uid)
        && (files?.file && !['application/pdf', 'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'image/jpeg', 'image/png']
          .includes(files?.file?.type))) {
        message.error('You can only upload PDF/DOC/JPEG/PNG file!');

        return;
      }

      const { list, uploading } = findListAndCurrentUploading();

      if (uploading && !uploading.loading && !uploader.loading) {
        Object.assign(uploading, {
          loading: true,
        });
        const formData = new FormData();

        formData.append('file', uploading.originFileObj as Blob);

        setLoadingFile(`${fullName}_${uploading.uid}`, true);
        uploader.fetch(formData)
          .finally(() => setLoadingFile(`${fullName}_${uploading.uid}`, false));

        form.setFieldValue(fullName, {
          file: uploading,
          fileList: list,
        });
      }
    }
  }, [files, uploader.loading]);

  useEffect(() => {
    if (!uploader.loading && uploader.error) {
      const { list, uploading } = findListAndCurrentUploading();

      if (uploading) {
        Object.assign(uploading, {
          id: null,
          error: uploader.error,
          status: 'error',
          loading: false,
        });
        form.setFieldValue(fullName, {
          file: uploading,
          fileList: list,
        });
      }
    }
  }, [uploader.loading, uploader.error]);

  useEffect(() => {
    if (!uploader.loading && uploader.data) {
      const { list, uploading } = findListAndCurrentUploading();

      if (uploading) {
        Object.assign(uploading, {
          key: uploader.data.id,
          id: uploader.data.id,
          error: null,
          status: 'done',
          loading: false,
          url: uploader.data.location,
        });

        form.setFieldValue(fullName, {
          file: uploading,
          fileList: list,
        });
      }
    }
  }, [uploader.loading, uploader.data]);

  return (
    <Form.Item
      name={name}
      {...props}
      {...rest}
      style={{ position: 'relative', ...style }}
      className={clsx(styles.upload, className)}
      extra={(
        <>
          {children}
          {rightText === false ? null : (
            <div
              className={styles.text}
              style={{ position: 'absolute', left: '100px', top: 0 }}
            >
              {rightText}
            </div>
          )}
        </>
        )}
    >
      <Upload
        key={JSON.stringify(files?.fileList)}
        beforeUpload={() => false}
        multiple={multiple}
        defaultFileList={files?.fileList}
        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp,.zip"
        {...uploadProps}
        // onDownload={handleDownload}
        onPreview={handleDownload}
      >
        <Button
          icon={<UploadOutlined />}
          loading={uploader.loading}
          disabled={maxLength ? ((files?.fileList || []).length) >= maxLength : undefined}
        >
          Upload
        </Button>
      </Upload>
    </Form.Item>
  );
}

export default OrderUpload;
