import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import clsx from 'clsx';
import { E164Number } from 'libphonenumber-js';

import styles from './index.module.scss';

interface PhoneNumberInput {
  size?: SizeType;
  value?: E164Number; // string;
  onChange?: (phone: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

function AlternativePhoneNumberInput({
  value, onChange, disabled, placeholder, size,
}: PhoneNumberInput): React.ReactNode {
  return (
    <PhoneInput
      className={clsx(styles.inputBlock, size && styles[size], 'alternative-phone-input')}
      disabled={disabled}
      // defaultCountry="CH"
      name="phone"
      placeholder={placeholder || 'Enter phone number'}
      value={value} // || ''
      onChange={(phone) => onChange?.(phone || '')}
      limitMaxLength
      international
      withCountryCallingCode
      /** To make flag not selectable add readOnly prop */
      // readOnly
      // onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
    />
  );
}

AlternativePhoneNumberInput.defaultProps = {
  size: 'middle',
  value: '',
  onChange: () => {},
  disabled: false,
  placeholder: '',
};

export default AlternativePhoneNumberInput;
