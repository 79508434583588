/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Dropdown, MenuProps, Modal, Spin, Tag, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DownloadOutlined, EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { getInvoiceStatusColor } from '..';
import {
  useInvoiceDownload,
  useInvoiceMarkAsPaid,
  useInvoicesDelete,
  useInvoiceResend,
  useInvoicesGetById,
  InvoiceStatusType,
  Invoice, useOrderInvoicePay,
} from '../../../../hooks/api/invoices';
import { downloadFromAnchor, getUserRequestPath } from '../../../../utils';
import { useAuth } from '../../../../store/auth';
import { ClientData, getClientData } from '../../Orders/Adapter';
import { Order } from '../../../../hooks/api/order';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import { isRoleEnough } from '../../../../enums/user';
import InvoiceDetailsContainer from './DetailsContainer';
import { isInvoiceDeleteVisible } from '../../Orders/View/InvoicesList';

import styles from './index.module.scss';

export interface SharedPropsInvoice {
  handleClose: () => void;
  afterAction?: () => Promise<any>;
  afterDelete?: () => void;
}

export interface InvoiceModalProps<T = Invoice> extends SharedPropsInvoice {
  setTableKey?: (value: number) => void,
  showFooter?: boolean,
  id: string,
  isModalOpen: boolean,
  dataSource?: T,
}

export interface InvoiceFooterProps<Data = Invoice> extends SharedPropsInvoice {
  orderId: string;
  itemId: string;
  itemNumber: string;
  itemData?: Data;
  setTableKey?: (value: number) => void;
  clearSelectedState?: () => void;
}

interface HeaderTitleProps {
  status: InvoiceStatusType;
}

function HeaderTitle({ status }: HeaderTitleProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
      <div className="ant-modal-title">Invoice Details</div>
      <Tag color={getInvoiceStatusColor(status)} style={{ fontWeight: '400' }}>
        {status}
      </Tag>
    </div>
  );
}

function FooterActions({
  orderId, itemId, itemNumber, itemData, handleClose, setTableKey, afterAction, afterDelete,
}: InvoiceFooterProps<Invoice>) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { open, contextHolder } = useSimpleModal();
  const invoiceResend = useInvoiceResend(getUserRequestPath(user?.role), itemId);
  const invoiceDelete = useInvoicesDelete(getUserRequestPath(user?.role), itemId);
  const downloadInvoice = useInvoiceDownload(getUserRequestPath(user?.role), itemId);
  const invoiceMarkAsPaid = useInvoiceMarkAsPaid(getUserRequestPath(user?.role), itemId);
  const invoicePayLink = useOrderInvoicePay(getUserRequestPath(user?.role), itemId);

  const isUserAdmin = isRoleEnough(user?.role, 'admin');
  const status = itemData?.status || 'draft';

  useEffect(() => {
    if ((!invoiceDelete.error && !invoiceDelete.loading && invoiceDelete.data)
    || (!invoiceMarkAsPaid.error && !invoiceMarkAsPaid.loading && invoiceMarkAsPaid.data)) {
      setTableKey?.(Date.now());
      handleClose();
    }
  }, [
    invoiceDelete.error, invoiceDelete.loading, invoiceDelete.data,
    invoiceMarkAsPaid.error, invoiceMarkAsPaid.loading, invoiceMarkAsPaid.data,
  ]);

  useMessageError([invoiceResend, invoiceDelete, downloadInvoice, invoiceMarkAsPaid, invoicePayLink]);
  useMessageSuccess([invoiceDelete], 'Invoice deleted successfully');
  useMessageSuccess([downloadInvoice], 'Download successfully');
  useMessageSuccess([invoiceMarkAsPaid], 'Invoice mark as paid');

  useEffect(() => {
    if (afterAction && (
      (invoiceMarkAsPaid.data && !invoiceMarkAsPaid.error && !invoiceMarkAsPaid.loading)
      || (invoiceDelete.data && !invoiceDelete.error && !invoiceDelete.loading)
      || (invoiceResend.data && !invoiceResend.error && !invoiceResend.loading)
      // || (invoicePayLink.data && !invoicePayLink.error && !invoicePayLink.loading)
    )) {
      afterAction();
      invoiceMarkAsPaid.clearResponse();
      invoiceDelete.clearResponse();
      invoiceResend.clearResponse();
    }
  }, [afterAction, invoiceMarkAsPaid.response, invoiceDelete.response, invoiceResend.response]);

  useEffect(() => {
    if (afterDelete && (invoiceDelete.data && !invoiceDelete.error && !invoiceDelete.loading)) {
      afterDelete();
      invoiceDelete.clearResponse();
    }
  }, [afterDelete, invoiceDelete.response]);

  const handlePay = () => {
    invoicePayLink.fetch()
      .then((res) => {
        if (res?.link) {
          window.location.href = res.link;
          // message.info('You will be redirected to payment system in few seconds.');
          // setTimeout(() => { window.location.href = res.link; }, 2000);
          // setTimeout(() => { window.location.replace(res.link); }, 2000);
        }
      });
  };

  const getInvoiceActions = (itemStatus: InvoiceStatusType) => {
    const isDeleteVisible = isInvoiceDeleteVisible(itemData);

    const commonItems: MenuProps['items'] = [
      {
        key: 'Resend',
        label: 'Resend',
        onClick: ({ domEvent }: any) => {
          domEvent.preventDefault();
          invoiceResend.fetch();
        },
      },
      {
        key: 'Download',
        label: 'Download',
        onClick: ({ domEvent }: any) => {
          domEvent.preventDefault();
          downloadInvoice.fetch().then((response) => {
            if (!response) return;
            downloadFromAnchor(response, `invoice_${itemNumber}`, 'application/pdf');
          });
        },
      },
    ];

    if (isDeleteVisible) {
      commonItems.push({
        key: 'Delete',
        label: 'Delete',
        danger: true,
        onClick: () => {
          open({
            icon: <ExclamationCircleFilled />,
            title: 'Delete invoice?',
            content: <span>{`Are you sure you want to delete invoice ${itemNumber}?`}</span>,
            cancelText: 'Cancel',
            okText: 'Delete',
            okButtonProps: { danger: true },
            onOk: () => {
              invoiceDelete.fetch();
            },
          });
        },
      });
    }

    return commonItems;
  };

  const adminDropdown = (
    <Dropdown
      menu={{ items: getInvoiceActions(status) }}
      placement="bottomRight"
      arrow
    >
      <Button icon={<EllipsisOutlined />} style={{ transform: 'translate(0px, 1.5px)' }} />
    </Dropdown>
  );

  return (
    <div className={styles.footerActions}>
      <div>
        {isUserAdmin && (status !== 'paid' && (
          <Button
            style={{ marginRight: '12px' }}
            onClick={(e) => {
              e.preventDefault();
              invoiceMarkAsPaid.fetch();
            }}
          >
            Mark as paid
          </Button>
        ))}
        {isUserAdmin ? (
          <Button
            type="primary"
            style={{ marginRight: '12px' }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/orders/${orderId}`);
            }}
          >
            View order
          </Button>
        ) : null}
        {!isUserAdmin && status && (['pending'] as InvoiceStatusType[]).includes(status) ? (
          <Button
            type="primary"
            style={{ marginRight: '12px' }}
            onClick={(e) => {
              e.preventDefault();
              handlePay();
            }}
            loading={invoicePayLink.loading}
          >
            Pay invoice
          </Button>
        ) : null}

        {isUserAdmin ? (
          adminDropdown
        ) : (
          <Button
            icon={<DownloadOutlined />}
            loading={downloadInvoice.loading}
            onClick={(e) => {
              e.preventDefault();
              downloadInvoice.fetch().then((response) => {
                if (!response) return;
                downloadFromAnchor(response, `invoice_${itemNumber}`, 'application/pdf');
              });
            }}
          />
        )}

        {contextHolder}
      </div>
    </div>
  );
}

function InvoicesModal({
  showFooter = false, id, isModalOpen, setTableKey, handleClose, afterAction, afterDelete, dataSource,
}: InvoiceModalProps) {
  const { user } = useAuth();
  const invoiceGetById = useInvoicesGetById(getUserRequestPath(user?.role));
  const [orderData, setOrderData] = useState<ClientData | undefined>({} as ClientData);

  useEffect(() => {
    if (id && !dataSource) {
      invoiceGetById.fetch(undefined, id);
    }
  }, [id, dataSource]);
  useEffect(() => {
    if (invoiceGetById.data || dataSource?.order) {
      setOrderData(
        getClientData(
          invoiceGetById.data?.order
        || dataSource?.order
        || {} as Order,
          'edit',
        ),
      );
    }
  }, [invoiceGetById.data, dataSource]);

  useMessageError([invoiceGetById]);

  const invoiceData = invoiceGetById.data || dataSource;

  const {
    number,
    createdAt,
    dueDate,
    status = 'draft',
    description,
  } = invoiceData || {};
  const {
    companyName,
    email,
    address1,
    address2,
    postalCode,
    city,
    country,
    paymentDays,
  } = orderData?.company || {};
  const {
    reference,
  } = orderData?.shipper || {};

  return (
    <Modal
      title={<HeaderTitle status={status} />}
      open={isModalOpen}
      destroyOnClose
      centered
      closable={!showFooter}
      maskClosable={false}
      width={1000}
      footer={false}
      onCancel={() => {
        // setOpen(false);
        handleClose();
      }}
    >
      <Divider />
      <div>
        <div className={styles.invoiceGrid}>
          <div className={styles.invoiceHeader}>
            {number ? (
              <h2>{`INVOICE ${number}`}</h2>
            ) : null}
            <img src="/logo.png" alt="Logo" />
          </div>

          {description ? (
            <Typography.Paragraph style={{ margin: '20px 0 0' }} className="truncate3">
              <b>Description: </b>
              {description}
            </Typography.Paragraph>
          ) : null}

          <div className={styles.invoiceInfo} style={{ marginTop: '30px' }}>
            <div className={styles.column}>
              <div>
                <strong>Created date:</strong>
                {createdAt ? (
                  <span>{dayjs(createdAt).format('D MMMM YYYY')}</span>
                ) : null}
              </div>
              <div>
                <strong>Due date:</strong>
                {dueDate ? (
                  <span>{dayjs(dueDate).format('D MMMM YYYY')}</span>
                ) : null}
              </div>
              <div>
                <strong>Shipper reference:</strong>
                {reference ? (
                  <span>{reference}</span>
                ) : null}
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <strong>Contact Email:</strong>
                <span>accounting@sephyre.com</span>
              </div>
            </div>

            <div className={styles.column}>
              {companyName ? (
                <span>{companyName}</span>
              ) : null}
              {/* {email ? (
                <span>{email}</span>
              ) : null} */}
              {address1 ? (
                <span>{address1}</span>
              ) : null}
              {address2 ? (
                <span>{address2}</span>
              ) : null}
              {postalCode ? (
                <span>{postalCode}</span>
              ) : null}
              {city ? (
                <span>{city}</span>
              ) : null}
              {country ? (
                <span>{country}</span>
              ) : null}
            </div>
            <div className={styles.column} />

            <InvoiceDetailsContainer.ShippingDetails invoice={invoiceData} orderData={orderData} />
          </div>
          <InvoiceDetailsContainer.Total invoice={invoiceData} orderData={orderData} />
        </div>
        <div style={{ padding: '0 25px' }}>

          <InvoiceDetailsContainer.Table invoice={invoiceData} orderData={orderData} />

          <div className={styles.invoiceInfo} style={{ marginTop: '48px' }}>
            <div className={styles.column}>
              <strong>Conditions of payment</strong>
              <span>{`Payable within ${paymentDays && paymentDays !== 0 ? paymentDays : 10} days`}</span>
              <span style={{ marginTop: '12px' }}>We remain at your disposal for any further questions</span>
              <span>Kind regards</span>
            </div>
          </div>
          <div className={styles.invoiceFooter}>
            <div className={styles.footerInfo}>
              <strong>Sephyre GmbH</strong>
              <span>CH-4450 Sissach, Switzerland  </span>
              <strong>Bank:</strong>
              <span>Raiffeisen</span>
              <strong>Account owner:</strong>
              <span>Sephyre GmbH</span>
              <strong>BIC:</strong>
              <span>RAIFCH22XXX</span>
            </div>
            <div className={styles.footerInfo}>
              <strong>IBAN:</strong>
              <span>CH16 8080 8006 3843 9480 1</span>
              <strong>VAT identification number:</strong>
              <span>CHE-232.506.635 MWST</span>
              <strong>Website:</strong>
              <span>sephyre.com</span>
            </div>
            <Divider />
            <div>
              <FooterActions
                orderId={invoiceData?.order?.id || ''}
                itemId={id}
                itemNumber={number || ''}
                itemData={invoiceData}
                // status={status}
                handleClose={handleClose}
                setTableKey={setTableKey}
                afterAction={afterAction}
                afterDelete={afterDelete}
              />
            </div>
          </div>
        </div>
      </div>
      {invoiceGetById.loading ? (
        <div className="spin-loading">
          <Spin />
        </div>
      ) : null}
    </Modal>
  );
}

export default InvoicesModal;
